import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

class WorkSection extends React.Component {

  render() {
    const { title, imgSrc, fluid, copy } = this.props
    return (
      <section className="c-workSection">
      <h4 className="c-workSection__title">
        {title}
      </h4>
      <article className="c-workSection__body">
        <figure className="c-workSection__figure">
          <Img
            alt=""
            key={imgSrc}
            fluid={fluid}
            imgStyle={{ objectFit: 'cover' }}
          />
        </figure>
        <p className="u-whiteSpacePreWrap">
          {copy}
        </p>
      </article>
    </section>

    )
  }

}

WorkSection.propTypes = {
  title: PropTypes.string,
  imgSrc:  PropTypes.object,
  fluid:  PropTypes.object,
  copy:  PropTypes.string,
};

export default WorkSection;