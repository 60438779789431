import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import Img from 'gatsby-image'
import WorkSection from '../components/WorkSection'

class WorkList extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <main className="l-main">
        <div className="l-container" data-spacing="mb8" data-spacingsp="mb6">
          <p className="u-alignCenter u-alignSpLeft u-lineHeight200" data-spacing="pb1" data-spacingsp="pb0">
            「売上アップに直接貢献した！」「大変だった業務が、一気に楽になった」など、お客様より喜びの声をいただいております。<br />
            導入事例を併せてご紹介いたします。
          </p>
        </div>
        <div className="u-bg--lightGray" data-spacing="pv8">
          <div className="l-container" data-spacing="pv8" data-spacingsp="pt1 ph0">
          {posts &&
            posts.map(({ node: post }) => (
              <section className="p-work" key={post.id}>
                <article
                  className={`p-work__container ${
                    post.frontmatter.featuredpost ? 'is-featured' : ''
                  }`}
                >
                  <header className="p-work__header--index">
                    <h2 className="p-work__title">{post.frontmatter.title}様</h2>
                    {post.frontmatter.featuredimage ? (
                      <div className="p-work__logo">
                        <Img
                          alt={post.frontmatter.title}
                          key={post.frontmatter.featuredimage}
                          fluid={post.frontmatter.featuredimage.childImageSharp.fluid}
                          // imgStyle={{ objectFit: 'contain',objectPosition: ' center right' }}
                          imgStyle={{ objectFit: 'contain'}}
                        />
                      </div>
                    ) : null}
                  </header>
                  <div className="p-work__body">
                    <h3 className="p-work__catchCopy">
                      {post.frontmatter.catchCopy}
                    </h3>
                    <p className="p-work__lead">
                      {post.frontmatter.description}
                    </p>
                    <p className="u-alignCenter" data-spacing="pt5 pb1">
                      <a
                        className="p-work__link"
                        href={post.frontmatter.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                          ［ {post.frontmatter.title}様ウェブサイトはこちらから ］
                      </a>
                    </p>
                    <WorkSection
                      title={post.frontmatter.comment.comment1.title}
                      imgSrc={post.frontmatter.clientPhoto}
                      fluid={post.frontmatter.clientPhoto.childImageSharp.fluid}
                      copy={post.frontmatter.comment.comment1.description}
                    />
                    <p className="u-alignCenter" data-spacing="pt1">
                      <Link className="c-button--skewYellow c-workSection__button" to={post.fields.slug}>
                        続きはこちら
                        <span className="c-icon--arrow c-button__arrow" />
                      </Link>
                    </p>
                  </div>
                </article>
              </section>
            ))}
          </div>
        </div>
      </main>
    )
  }
}

WorkList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query WorkListQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {
            frontmatter: {
              templateKey: { eq: "work-post" }
              featuredpost: { eq: true }
            }
          }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                description
                url
                catchCopy
                date(formatString: "MMMM DD, YYYY")

                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid( maxHeight: 100, quality: 85) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                clientPhoto {
                  childImageSharp {
                    fluid( maxWidth: 90, quality: 85) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                comment{
                  comment1{
                    title
                    description
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <WorkList data={data} count={count} />}
  />
)
